@font-face {
  font-family: 'bebasneue_regular-webfont';
  src: url('/fonts/Trade-Font/bebasneue_regular-webfont.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TradeGothicLTStd-BdCn20';
  src: url('/fonts/Trade-Font/TradeGothicLTStd-BdCn20.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TradeGothicLTStd-Cn18';
  src: url('/fonts/Trade-Font/TradeGothicLTStd-Cn18.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-BlackItalic.eot'),
    url('/fonts/Amx/AMX-BlackItalic.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-BlackItalic.svg')  format('svg'),
    url('/fonts/Amx/AMX-BlackItalic.woff') format('woff'),
    url('/fonts/Amx/AMX-BlackItalic.woff2')  format('woff2');
    font-weight: 800;
    font-style: italic;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-BoldItalic.eot'),
    url('/fonts/Amx/AMX-BoldItalic.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-BoldItalic.svg')  format('svg'),
    url('/fonts/Amx/AMX-BoldItalic.woff') format('woff'),
    url('/fonts/Amx/AMX-BoldItalic.woff2')  format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-Italic.eot'),
    url('/fonts/Amx/AMX-Italic.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-Italic.svg')  format('svg'),
    url('/fonts/Amx/AMX-Italic.woff') format('woff'),
    url('/fonts/Amx/AMX-Italic.woff2')  format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-Light.eot'),
    url('/fonts/Amx/AMX-Light.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-Light.svg')  format('svg'),
    url('/fonts/Amx/AMX-Light.woff') format('woff'),
    url('/fonts/Amx/AMX-Light.woff2')  format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-Medium.eot'),
    url('/fonts/Amx/AMX-Medium.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-Medium.svg')  format('svg'),
    url('/fonts/Amx/AMX-Medium.woff') format('woff'),
    url('/fonts/Amx/AMX-Medium.woff2')  format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-Black.eot'),
    url('/fonts/Amx/AMX-Black.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-Black.svg')  format('svg'),
    url('/fonts/Amx/AMX-Black.woff') format('woff'),
    url('/fonts/Amx/AMX-Black.woff2')  format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-Bold.eot'),
    url('/fonts/Amx/AMX-Bold.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-Bold.svg')  format('svg'),
    url('/fonts/Amx/AMX-Bold.woff') format('woff'),
    url('/fonts/Amx/AMX-Bold.woff2')  format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-LightItalic.eot'),
    url('/fonts/Amx/AMX-LightItalic.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-LightItalic.svg')  format('svg'),
    url('/fonts/Amx/AMX-LightItalic.woff') format('woff'),
    url('/fonts/Amx/AMX-LightItalic.woff2')  format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-MediumItalic.eot'),
    url('/fonts/Amx/AMX-MediumItalic.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-MediumItalic.svg')  format('svg'),
    url('/fonts/Amx/AMX-MediumItalic.woff') format('woff'),
    url('/fonts/Amx/AMX-MediumItalic.woff2')  format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AMX';
  src: url('/fonts/Amx/AMX-Regular.eot'),
    url('/fonts/Amx/AMX-Regular.ttf') format('truetype'), 
    url('/fonts/Amx/AMX-Regular.svg')  format('svg'),
    url('/fonts/Amx/AMX-Regular.woff') format('woff'),
    url('/fonts/Amx/AMX-Regular.woff2')  format('woff2');
  font-weight: normal;
  font-style: normal;
}
